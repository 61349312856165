
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { ElNotification, FormInstance, FormRules } from 'element-plus'

export default defineComponent({
  setup () {
    const pwdCompare = (rule: any, value: string, callback: any) => {
      if (!value) {
        callback(new Error('请再次输入新密码'))
      } else if (value !== fromData.value.passwordNew) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }

    const ruleFormRef = ref<FormInstance>()
    const rules = reactive<FormRules>({
      mobile: [
        { required: true, message: '请输入电话', trigger: 'blur' },
        { min: 7, max: 11, message: '电话位数不足，请核对', trigger: 'blur' },
        { pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/, message: '请输入正确的电话', trigger: 'blur' }
      ],
      code: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { pattern: /^\d{6}$/, message: '请输入正确的验证码', trigger: 'blur' }
      ],
      passwordOld: [
        { required: true, message: '请输入旧密码', trigger: 'blur' }
      ],
      passwordNew: [
        { required: true, message: '请输入新密码', trigger: 'blur' }
      ],
      passwordCompare: [
        { required: true, validator: pwdCompare, trigger: 'blur' }
      ]
    })

    const fromData = ref({
      mobile: '',
      code: '',
      passwordOld: '',
      passwordNew: '',
      passwordCompare: ''
    })

    const type = ref('1')
    const isShow = computed(() => type.value === '1')

    const sendCode = () => {
      ElNotification({
        type: 'success',
        title: '获取验证码成功',
        message: '本次验证码是：123456'
      })
    }

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.resetFields()
    }

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          console.log('submit!')
        } else {
          console.log('error submit!', fields)
        }
      })
    }

    return {
      ruleFormRef,
      rules,
      fromData,
      type,
      isShow,
      sendCode,
      resetForm,
      submitForm,
      pwdCompare
    }
  }
})
